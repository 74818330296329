import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {BuildProfile} from "../interfaces/benchmark";
import {CompareBarService} from "../compare-bar.service";
import {BenchmarkService} from "../benchmark.service";
import {MatDialog} from "@angular/material/dialog";
import {ProfilePhotoUploadComponent} from "../profile-photo-upload/profile-photo-upload.component";
import {Lightbox} from "ngx-lightbox";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'benchmark-profile',
    templateUrl: './benchmark-profile.component.html',
    styleUrls: ['./benchmark-profile.component.css']
})
export class BenchmarkProfileComponent implements OnInit {

    @Input() profileResult: BuildProfile = null;
    @Input() fromDashboard: boolean = false
    loading = true
    editingTitle = false
    showDelete = false
    showPhotosSection = false
    createdByUser = {uid: 'any', display_name: ''};
    // Logged-in user owns this build
    loggedInUser = false;
    copyUrl = ''
    profileId
    constructor(private route: ActivatedRoute,
                public dialog: MatDialog,
                private _lightbox: Lightbox,
                private benchmarkService: BenchmarkService,
                private compareBarService: CompareBarService,
                private _snackBar: MatSnackBar) {
        this.profileId = this.route.snapshot.paramMap.get('resultId')
    }
    activateEditTitle(){
        if(!this.profileResult.title){
            this.profileResult.title = 'Build Profile'
        }
        this.editingTitle = true
    }
    editTitle(){
        this.editingTitle = false
        this.benchmarkService.editTitle(this.profileResult.rid, this.profileResult.title).subscribe(response=>{

        })

    }

    addToCompareBar() {
        this.compareBarService.addBenchmark(this.profileResult)
    }
    share(){
        this._snackBar.open('Copied URL to clipboard', 'Dismiss', {
            duration: 5000
        });
    }
    deletePhoto(image){
        console.log(image)
        this.benchmarkService.deletePhoto(this.profileResult.rid, image).subscribe(response => {

        })
    }
    deleteBenchmark(index, benchmarkId){
        this.benchmarkService.deleteBenchmark(this.profileResult.rid, benchmarkId).subscribe(response=>{
            this.profileResult.benchmarks.splice(index, 1)
        })
    }

    uploadPhoto() {
        let dialogRef = this.dialog.open(ProfilePhotoUploadComponent, {data: this.profileResult});
        dialogRef.afterClosed().subscribe(success => {
            if (success === true) {
                console.log("Photo upload")
            }
        });
    }

    ngOnInit() {
        if (!this.profileResult) {
            this.benchmarkService.getProfile(this.profileId).subscribe(response => {
                this.profileResult = new BuildProfile(response['build'])
                this.loggedInUser = response['is_logged_in_user']
                this.createdByUser = response['created_by_user']
                if(this.loggedInUser || (!this.loggedInUser && this.profileResult.images.length > 0)){
                    this.showPhotosSection = true
                }
                this.loading = false
            })
        } else {
            this.loading = false
        }

        this.copyUrl = "https://comparebench.com/build/"+this.profileId
    }

    handleCarouselEvents(event){
        if (event.name == 'click') {
            console.log(event)
            this._lightbox.open(this.profileResult.images, event.imageIndex);
            // this.gallery.load(this.profileResult.images[event.imageIndex]);
        }

    }



}
