import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserService} from './user.service';
import {Observable} from 'rxjs';
import {CheckboxTaskCpu, CheckboxTaskGpu, ProductCPU, ProductGPU} from './interfaces/products';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

    constructor(private http: HttpClient) {
    }

    cpuProduct: string = UserService.getHostname() + '/products/cpu';
    gpuProduct: string = UserService.getHostname() + '/products/gpu';
    reviews: string = UserService.getHostname() + '/reviews/fetch';  // TODO
    performance: string = UserService.getHostname() + '/products/performance';

    getReviews(type: string, id: any): Observable<any[]> {
        return this.http.post<any>(this.reviews, {type: type, id: id}, {});
    }
    getPerformance(type: string, id: any): Observable<any> {
        return this.http.get<any>(`${this.performance}/${type}/${id}`, {});
    }
    getProductCpu(cpuid?): Observable<ProductCPU[]> {
        let body = {};
        if (cpuid) {
            body = {id: cpuid};
        }
        return this.http.post<ProductCPU[]>(this.cpuProduct, body, {});
    }
    getProductSingleCpu(cpuid): Observable<ProductCPU[]> {
        return this.http.get<ProductCPU[]>(this.cpuProduct + '/' + cpuid, {});
    }
    getProductGpu(gid?): Observable<ProductGPU[]> {
        let body = {};
        if (gid) {
            body = {id: gid};
        }
        return this.http.post<ProductGPU[]>(this.gpuProduct, body, {});
    }

    getProductCpuFilters(allMakes: CheckboxTaskCpu, searchText: string, page: number, pageSize: number): Observable<ProductCPU[]> {
        const body = {
            cpu: [],
            search: null,
            page: page,
            limit: pageSize,
        };
        if (searchText){
            body.search = searchText;
        }
        for (let i = 0; i < allMakes.subtasks.length; i++){
            if (allMakes.subtasks[i].completed) {
                if (allMakes.subtasks[i].intel) {
                    body.cpu.push('Intel');
                }
                if (allMakes.subtasks[i].amd) {
                    body.cpu.push('AMD');
                }
            }
        }
        return this.http.post<ProductCPU[]>(this.cpuProduct, body, {});
    }
    getProductGpuFilters(allMakes: CheckboxTaskGpu, searchText: string, page: number, pageSize: number): Observable<ProductGPU[]> {
        const body = {
            gpu: [],
            search: null,
            page: page,
            limit: pageSize,
        };
        if (searchText){
            body.search = searchText;
        }
        for (let i = 0; i < allMakes.subtasks.length; i++){
            if (allMakes.subtasks[i].completed) {
                if (allMakes.subtasks[i].intel) {
                    body.gpu.push('Intel');
                }
                if (allMakes.subtasks[i].amd) {
                    body.gpu.push('AMD');
                }
                if (allMakes.subtasks[i].nvidia) {
                    body.gpu.push('NVIDIA');
                }
            }
        }
        return this.http.post<ProductGPU[]>(this.gpuProduct, body, {});
    }

}
