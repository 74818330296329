<header class="topbar">
    <nav class="navbar top-navbar navbar-expand-md navbar-light" style="    max-height: 70px;">

        <div class="navbar-header" style="z-index: 99999;cursor:pointer; display: flex;width: unset !important;">
            <span class="navbar-brand" style="display: flex;justify-content: space-around">
                <!-- side navigation -->
                <div class="sidenav">
                    <button aria-label="menu" mat-flat-button (click)="toggleNav()" style="height:45px;background-color: #2a3e52"><i style="font-size: 25px" class="fa-solid fa-bars"></i></button>
                    <div [@openClose]="navOpen ? 'open' : 'closed'">

                        <div style="flex-direction: column;background-color: #292a2d">
                            <navigation-items style="display: block;width:200px"></navigation-items>
                        </div>
                    </div>
                </div>

            </span>
        </div>
        <span [routerLink]="['/']" class="landing-title-wrap">
                    <img style="margin-left: 5px;" height="50" width="51" priority
                         ngSrc="https://r2.comparebench.com/assets/logo_button.webp" alt="homepage" class="light-logo"/>
                    <!--End Logo icon -->
                    <!-- Logo text -->
                    <span class="landing-title">
                        <span style="color:white;font-size: 20px;padding-left: 5px;">CompareBench</span>
                        <span class="badge badge-info" style="font-size: 15px">Beta</span>
                    </span>
                </span>
        <span style="padding-left: 10px" class="patreon-wrap">
                <a href="https://www.patreon.com/comparebench" (click)="clickPatreon()" class="patreon"
                   style="color: white"
                   target="_blank"><div><img src="assets/patreon.png" alt="patreon logo" width="16px"
                                             style="margin: 0 5px 0 0;">Support us!</div></a>
        </span>
        <div class="navbar member-signin">
            <ul class="navbar-nav mr-auto">
                <!-- This is  -->
                <li class="nav-item"><span class="nav-link nav-toggler hidden-md-up waves-effect waves-dark"><i
                    class="ti-menu"></i></span></li>
                <li class="nav-item hidden-sm-down"><span></span></li>
            </ul>
            <ul class="navbar-nav my-lg-0">
                <!-- ============================================================== -->
                <!-- Profile -->
                <!-- ============================================================== -->
                <li class="nav-item dropdown">
                    <div [hidden]="!loggedIn">
                        <button mat-flat-button (click)="userCardOpen = !userCardOpen" cdkOverlayOrigin
                                #trigger="cdkOverlayOrigin" style="min-width: unset;padding: 0"><img
                            src="/assets/233.png" width="30px" alt="user"/></button>
                    </div>
                    <a class="nav-link dropdown-toggle " *ngIf="!loggedIn" routerLink="/login"
                       aria-haspopup="true" aria-expanded="false">
                        <button mat-raised-button [color]="'primary'">Log In/Sign Up</button>
                    </a>

                    <ng-template
                        cdkConnectedOverlay
                        [cdkConnectedOverlayOrigin]="trigger"
                        [cdkConnectedOverlayOpen]="userCardOpen"
                        [cdkConnectedOverlayPositions]="positions"
                        [cdkConnectedOverlayOffsetX]="30"
                        (overlayOutsideClick)="userCardOpen = false"
                    >
                        <div style="background-color: #292a2d;border-radius: 5px;min-width: 250px;">
                            <div *ngIf="loggedIn" style="display: flex;    flex-direction: column;    ">
                                <div style="padding: 10px 15px;">
                                    <div style="    display: flex;flex-direction: row;">
                                        <div><img src="/assets/233.png" alt="user" style="width: 70px"></div>
                                        <div style="    padding-left: 10px;">
                                            <h4>{{ user.displayName }}</h4>
                                            <!--                                            <p>{{user.username}}</p>-->
                                            <!--                                        <p>Free tier</p>&lt;!&ndash; class="btn btn-rounded btn-primary btn-sm" href="/pricing">Upgrade</a&ndash;&gt;-->
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <hr style="border-top: 1px solid #ffffff1f">
                                </div>
                                <div class="menuItem"><a style="color:#babcbd" (click)="userCardOpen=false"
                                                         routerLink="/profile">
                                    <i class="fa-regular fa-user"></i>
                                    My Profile</a></div>
                                <div class="menuItem"><a (click)="logout()">
                                    <i style="-webkit-transform: scaleX(-1);
    transform: scaleX(-1);" class="fa-solid fa-right-from-bracket"></i>
                                    Logout</a>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
        </div>
    </nav>
</header>
<aside class="left-sidebar" style="width: 100%;position: fixed;max-height: 128px;">
    <nav class="main-nav" style="flex-direction:row;height: 58px;width:1280px;padding-left: 15px;margin: 0 auto;">
            <navigation-items style="display: flex"></navigation-items>
    </nav>
</aside>
<div *ngIf="navOpen" style="position: fixed;width:100%;height:100%;z-index: 2" (click)="navOpen = false">
</div>
