<div>
    <div class="container-fluid">
        <div class="row page-titles" style="justify-content: center;">
            <div class="col-md-4 align-self-center">
                <h3>Log In</h3>
            </div>
        </div>
        <div class="row" style="justify-content: center;">
            <div class="card col-md-4">
                <div class="card-body">

                    <form [formGroup]="loginForm" style="text-align: center;min-width: 200px">
                        <mat-form-field style="min-width: 250px">
                          <mat-label>Email</mat-label>
                          <input class="authInput" matInput type='text' formControlName="email">
                        </mat-form-field>
                        <br>
                        <mat-form-field style="min-width: 250px">
                          <mat-label>Password</mat-label>
                          <input class="authInput" matInput type='password' formControlName="password">
                        </mat-form-field>
                        <br>
                        <button mat-raised-button name="form.submitted" (click)="login()" style="margin: 10px 0 5px 0;">Log In</button>
                        <br>
                        <h6>Don't have an account? <a routerLink="/signup" >Sign Up</a></h6>
                    </form>

                    <hr>
                    <div style="display: flex;justify-content: center;flex-direction: column;text-align: center;">
                        <span>Or Login with</span><br>
                        <button mat-button (click)="discord()"><img src="/assets/discord_logo.png" style="width: 50px"></button>
                    </div>
<!--                <h6>Forgot your password? <a href="/resetpassword">Reset Password</a></h6>-->
                </div>
            </div>
        </div>
    </div>
</div>
