<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<div *ngIf="!loading">
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 class="text-themecolor" *ngIf="!editingTitle" style="color: #babcbd !important;">
                <a [routerLink]="['/profile', createdByUser.uid]"
                   style="color: #009efb">{{ createdByUser.display_name }}</a>'s {{ profileResult.title || "Build Profile" }}
                <button mat-button *ngIf="loggedInUser" (click)="activateEditTitle()">
                    <i class="fa-solid fa-pen"></i>
                </button>
            </h3>
            <input *ngIf="editingTitle" type="text" placeholder="{{profileResult.title}}"
                   class="form-control required" [(ngModel)]="profileResult.title">
            <button *ngIf="editingTitle && loggedInUser" (click)="editTitle()">Save</button>
        </div>
    </div>
    <div class="row ">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex">
                        <div>
                            <h4 class="card-title">
                                <span class="lstick"></span>
                                Specifications
                            </h4>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-3 col-xs-6 b-r"><span class="text-muted">CPU</span>
                            <br>
                            <!--                                <p><strong><a href="/products/cpu/{{benchmark-creation.cpu_id}}">{{benchmark-creation.model}} @ {{benchmark-creation.freq}}</a></strong></p>-->
                            <p>
                                <strong>
                                    <a routerLink="/products/cpu/{{profileResult.cpu.cpuid}}"
                                       style="color: #009efb;cursor: pointer">{{ profileResult.model }}</a>
                                    <span *ngIf="profileResult.freq"> @ {{ profileResult.freq | mhzToGhz }} GHz
                                    </span>
                                </strong>
                            </p>
                        </div>
                        <div class="col-md-3 col-xs-6 b-r"><span class="text-muted">GPU</span>
                            <br>
                            <span *ngFor="let gpu of profileResult.gpu">
                                <p><strong>{{ gpu.make }} {{ gpu.model }}</strong></p>
                            </span>
                        </div>
                        <div class="col-md-3 col-xs-6 b-r"><span class="text-muted">Memory</span>
                            <br>
                            <p style="margin-bottom: 0"><strong>{{ profileResult.mem_total }}GB</strong></p>
                            <span style="font-size: 12px">{{ profileResult.memory[0].modelname }}</span>
                        </div>
                        <div class="col-md-3 col-xs-6 b-r" style="text-align: center;">
                            <div class="btn btn-info addCompare" (click)="addToCompareBar()">Compare</div>
                            <div class="btn btn-info shareBtn" [cdkCopyToClipboard]="copyUrl" (click)="share()">
                                Share
                                <i class="fa-regular fa-copy"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="row" *ngIf="showPhotosSection">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex">
                        <div>
                            <h4 class="card-title">
                                <span class="lstick"></span>
                                Photos
                            </h4>
                        </div>
                        <div class="ml-auto">
                            <button class="btn btn-sm btn-info" (click)="showDelete = !showDelete"
                                    *ngIf="loggedInUser && profileResult.images.length > 0">
                                Delete Photos
                            </button>
                            <button class="btn btn-sm btn-info" *ngIf="loggedInUser" (click)="uploadPhoto()">
                                Add Photo
                            </button>
                        </div>
                    </div>
                    <div class="row" style="padding: 0 15px 0 15px;" *ngIf="!showDelete">
                        <carousel [images]="profileResult.images" *ngIf="profileResult.images.length > 0"
                                  (events)="handleCarouselEvents($event)"></carousel>
                    </div>
                    <div *ngIf="loggedInUser && showDelete">
                        <div *ngFor="let image of profileResult.images">
                            <img [src]="image.src" style="width: 100px">
                            <button mat-raised-button (click)="deletePhoto(image)">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="card" style="margin-bottom: 0;">
                <div class="card-body">
                    <div class="d-flex">
                        <div>
                            <h4 class="card-title">
                                <span class="lstick"></span>
                                Benchmarks
                            </h4>
                        </div>
                        <div class="ml-auto">
                            <button class="btn btn-sm btn-info" *ngIf="loggedInUser"
                                    routerLink="/benchmark/{{profileResult.rid}}/add">
                                Add result
                            </button>
                        </div>
                    </div>
                    <div *ngIf="profileResult.benchmarks.length>0">
                        <mat-accordion class="example-headers-align" multi *ngFor="let benchmark of profileResult.benchmarks; index as index">
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{benchmark.benchmark_type_name}}&nbsp;
                                        <span style="color:#8b8a8a" *ngIf="benchmark.benchmark_type_version">
                                            {{benchmark.benchmark_type_version}}
                                        </span>
                                    </mat-panel-title>
                                    <mat-panel-description>
                                        {{benchmark.averageScore | number: '1.0-0'}} <span *ngIf="benchmark.benchmark_type == 16">fps</span>
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <div>
                                    Run details
                                    <div class="col-md-6" *ngIf="benchmark.graphOpts">
                                        <canvas class="graph" [type]="benchmark.graphOpts.type"
                                                [datasets]="benchmark.graphOpts.data.datasets"
                                                [labels]="benchmark.graphOpts.data.labels"
                                                [options]="benchmark.graphOpts.options"
                                                baseChart>
                                        </canvas>
                                    </div>
                                    <button mat-button style="margin-right: 5px;float: right;" (click)="deleteBenchmark(index, benchmark.id)" type="button">
                                        <i class="fa-solid fa-trash"></i>
                                    </button>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <p *ngIf="profileResult.benchmarks.length == 0">No benchmarks added yet</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="font-size: 13px;padding: 5px;color: #67757c;" *ngIf="profileResult.source == 'Yardstick'">
        <em>Uploaded via <a style="color: #398bf7;" routerLink="/yardstick">Yardstick</a></em>
    </div>
</div>
