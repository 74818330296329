import {Component, OnInit} from '@angular/core';
import {UserService} from "../user.service";
import {Router} from "@angular/router";
import {ConnectionPositionPair} from "@angular/cdk/overlay";
import posthog from "posthog-js";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.css'],
    animations: [
        trigger('openClose', [
            // ...
            state('open', style({
                left: '0',
                position: 'absolute',
            })),
            state('closed', style({
                left: '-200px',
                position: 'absolute',
            })),
            transition('open => closed', [
                animate('.3s')
            ]),
            transition('closed => open', [
                animate('0.3s')
            ]),
        ]),
    ]
})
export class NavigationComponent implements OnInit {
    email;
    password;
    user;
    loggedIn = false;
    userCardOpen = false;
    navOpen = false;
    positions = [
        new ConnectionPositionPair({ originX: 'start', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' }),
        new ConnectionPositionPair({ originX: 'start', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' }),
    ]

    constructor(private userService: UserService, private router: Router) {
    }
    clickPatreon() {
        posthog.capture(
            'patreon_button_clicked',
        )
    }
    toggleNav() {
        this.navOpen = !this.navOpen;
    }
    login() {
        this.userService.login(this.email, this.password).subscribe((response) => {
            if (response.code != 403) {
                this.user = true;
                this.loggedIn = true;
                this.router.navigate(['/dashboard'])
            }
        })
    }

    logout() {
        this.userService.logout().subscribe(() => {
            this.userCardOpen = false;
            this.user = false;
            this.loggedIn = false;
            this.userService.loggedIn.next(false);
            this.router.navigate(['/'])
        })
    }

    ngOnInit() {
        this.userService.isLoggedIn().subscribe(isLoggedIn=>{
            if(isLoggedIn){
                this.user = this.userService.user
                this.loggedIn = true
            }
        })
    }

}
