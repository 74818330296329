<a mat-flat-button routerLink="/dashboard" aria-expanded="false">
    <i class="fa-solid fa-gauge fa-xl"></i>
    <span>Dashboard</span>
</a>
<a mat-flat-button routerLink="/games" aria-expanded="false">
    <i class="fa-solid fa-gamepad fa-xl"></i>
    <span>Game Performance</span>
</a>
<button mat-flat-button [matMenuTriggerFor]="menu" class="dropdown-arrow"
        aria-label="Example icon-button with a menu">
    <i class="fa-solid fa-microchip fa-xl"></i>
    Products
</button>
<mat-menu #menu="matMenu" class="nestedmenu">
    <button mat-menu-item routerLink="/products/cpu">
        <i class="fa-solid fa-microchip"></i>
        <span>CPUs</span>
    </button>
    <button mat-menu-item routerLink="/products/gpu">
        <i class="fa-solid fa-microchip"></i>
        <span>GPUs</span>
    </button>

</mat-menu>
<a mat-flat-button routerLink="/roadmap" aria-expanded="false">
    <i class="fa-solid fa-map fa-xl"></i>
    <span class="hide-menu">Roadmap</span>
</a>
<a mat-flat-button routerLink="/faq" aria-expanded="false">
    <i class="fa-solid fa-question fa-xl"></i>
    <span>FAQ</span>
</a>
<a mat-flat-button routerLink="/yardstick" aria-expanded="false">
    <i class="fa-solid fa-ruler-horizontal fa-xl"></i>
    <span>Yardstick</span>
</a>
